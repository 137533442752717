/*--------------------------------------------------------------
2. Preloader
----------------------------------------------------------------*/
.cs_perloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .cs_perloader_in {
    position: relative;

    svg {
      width: 110px;
      height: 110px;
    }
  }

  .cs_wave_first {
    svg {
      fill: rgba($accent, 0.15);
    }
  }

  .cs_wave_second {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    animation: spark 1.25s linear infinite;

    svg {
      fill: $accent;
    }
  }
}

@keyframes spark {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}
